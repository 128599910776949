import { Injectable, computed, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  ParticipantDto,
  ParticipantModel,
  PlayerModel,
} from '@ay-gosu-party/server-shared';
import { ExposedPromise } from '@ay/util/exposed-promise';
import { jwtDecode } from 'jwt-decode';
import { filter } from 'rxjs';
import { ConnectionService } from '../connection.service';

export const GOSU_PARTY_MOBILE_LOGIN_TOKEN = (eventId: string) =>
  `GOSU_PARTY_MOBILE_LOGIN_TOKEN_${eventId}`;

export enum LoginStatus {
  // 還沒有處理自動登入
  INIT,
  // 未登入(已經處理完自動登入)
  NOT_LOGGED_IN,
  // 已登入，且已選擇組織
  LOGGED,
}

export const INIT = LoginStatus.INIT;
export const NOT_LOGGED_IN = LoginStatus.NOT_LOGGED_IN;
export const LOGGED = LoginStatus.LOGGED;

@Injectable({ providedIn: 'root' })
export class MobileLoginService {
  public eventId = signal<string | null>(null);

  // 儲存登入後的 JWT
  // 如果是 null 則尚未登入
  // 儘管有值時，有可能還沒選擇組織
  public token = signal<string | null>(null);

  public participant = computed(() => {
    const token = this.token();
    if (!token) return null;
    const payload = jwtDecode(token) as ParticipantDto;
    if (!payload.participant) return null;
    return payload;
  });

  // 登入的狀態
  public status = computed(() => {
    if (!this._connectionService.isConnected()) {
      return INIT;
    }

    if (!this.participant()) {
      return NOT_LOGGED_IN;
    }

    return LOGGED;
  });

  public enableSaveToken = signal(false);

  // 確認是否已經登入的 signal
  public isLogged = computed(() => this.status() === LOGGED);

  public isJoined = signal(false);

  public afterAutoLogin = new ExposedPromise<void>();

  public constructor(
    private readonly _connectionService: ConnectionService,
    private readonly _router: Router,
  ) {
    // 當連線完畢後，處理自動登入機制
    this._connectionService.connected$
      .pipe(filter((status) => status))
      .subscribe(() => this._onConnected());
    this._storeTokenToLocalStorage();
  }

  public async loginViaToken(token: string) {
    this.token.set(token);
    await this._afterLogin();
  }

  private async _onConnected() {
    if (!this.afterAutoLogin.isPending()) {
      this.afterAutoLogin = new ExposedPromise<void>();
    }

    try {
      await this._processAutoLogin();
    } catch (error) {
      console.error(error);
    } finally {
      this.enableSaveToken.set(true);
      this.afterAutoLogin.resolve();
    }
  }

  private async _processAutoLogin() {
    if (location.href.includes('after-oauth-login')) {
      return;
    }

    const eventId = this.eventId();
    if (!eventId) return;

    const key = GOSU_PARTY_MOBILE_LOGIN_TOKEN(eventId);
    const token = localStorage.getItem(key);

    if (!token) {
      this.token.set(null);
    } else {
      try {
        const renewedToken = await ParticipantModel.refresh(token);
        this.token.set(renewedToken);
      } catch (error) {
        this.token.set(null);
      }
    }

    await this._afterLogin();
  }

  private async _afterLogin() {
    const eventId = this.eventId();
    if (!eventId) return;

    // 如果已經有加入過，就直接轉跳到活動頁、否則轉跳到頭像設定頁
    const valid = await PlayerModel.isValidJoined(eventId);
    if (!this.isLogged()) {
      if (!valid) {
        this._router.navigateByUrl(`/m/${eventId}/oops`);
      } else {
        this._router.navigateByUrl(`/m/${eventId}/login`);
      }
    } else {
      const isJoined = await PlayerModel.isJoined(eventId).catch(() => false);
      this.isJoined.set(isJoined);
      if (!isJoined) {
        if (!valid) {
          this._router.navigateByUrl(`/m/${eventId}/oops`);
        } else {
          this._router.navigateByUrl(`/m/${eventId}/avatar`);
        }
      } else {
        this._router.navigateByUrl(`/m/${eventId}/player`);
      }
    }
  }

  private async _storeTokenToLocalStorage() {
    effect(() => {
      const eventId = this.eventId();
      if (!eventId) return;

      if (!this.enableSaveToken()) return;

      const token = this.token();
      const key = GOSU_PARTY_MOBILE_LOGIN_TOKEN(eventId);

      if (!token) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, token);
      }
    });
  }
}
